<template>
  <table class="print-table">
    <thead>
      <tr>
        <th width="24">序号</th>
        <th>品名/工艺</th>
        <th>材质</th>
        <th>规格(mm)</th>
        <th>单位</th>
        <th class="text-right">数量</th>
        <th class="text-right">单价(元)</th>
        <th class="text-right">金额(元)</th>
        <th>备注</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in selfData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.craft }}</td>
        <td>{{ item.texture }}</td>
        <td>{{ item.specification }}</td>
        <td>{{ item.unit }}</td>
        <td class="text-right">{{ item.cai_gou_stock_counts }}</td>
        <td class="text-right">{{ item.cai_gou_stock_price }}</td>
        <td class="text-right">{{ item.cai_gou_stock_amount }}</td>
        <td>{{ item.cai_gou_remark_stock }}</td>
      </tr>
      <tr>
        <td>合计</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-right">{{ totalCounts }}</td>
        <td></td>
        <td class="text-right">{{ totalSalesAmount }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfData: [],
      totalCounts: 0,
      totalSalesAmount: 0
    }
  },
  mounted() {
    this.selfData = this.$util.deepCopy(this.data)
    this.totalCounts = this.sumNum(this.selfData, 'cai_gou_stock_counts')
    this.totalSalesAmount = this.sumNum(this.selfData, 'cai_gou_stock_amount')
  },
  methods: {
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    }
  }
}
</script>

<style lang="less" scoped>
.print-table {
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.print-table th {
  font-weight: normal;
  background: #e0e5eb;
}
</style>
